/** @jsx jsx */
import React, { useEffect } from 'react';
import { Flex, jsx, useThemeUI } from 'theme-ui';
import { Link } from 'gatsby';
import { periodProducts } from '~/lib/products';
import useWindowSize from '~/hooks/components/use-window-size';

const YotpoStars = ({ slug }) => {
  const { theme } = useThemeUI();
  const { width } = useWindowSize();

  useEffect(() => {
    if (!window.yotpoWidgetsContainer && !window.yotpo) return;

    if (window.yotpoWidgetsContainer) window.yotpoWidgetsContainer.initWidgets();
    if (window.yotpo) window.yotpo.refreshWidgets();
  }, []);

  return (
    <Flex
      sx={{
        mt: '10px',
        '#yotpoStarsComponent .yotpo-bottomline .yotpo-icon-star': {
          color: 'canary',
        },
        '#yotpoStarsComponent a': {
          color: `${theme.colors.alizarinCrimson} !important`,
          fontFamily: 'halcom !important',
          ml: '4px',
        },
        '#yotpoStarsComponent .text-m': {
          fontSize: '1.2rem',
        },
        '#yotpoStarsComponent .standalone-bottomline.star-clickable, #yotpoStarsComponent .standalone-bottomline .star-clickable ':
          {
            flexDirection: 'row',
            mt: 0,
          },
      }}
    >
      <Link to="/products/box-builder#yotpoReviewsWidget" id="yotpoStarsComponent">
        <div
          className="yotpo bottomLine"
          data-product-id={periodProducts.includes(slug) ? 'box-builder' : slug}
        />
      </Link>
    </Flex>
  );
};

export default YotpoStars;
