import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { Box, Flex } from 'theme-ui';
import TraceabilityHotspots from '~/components/Product/TraceabilityHotspots';
import { TreceabilityBubble } from '../TreceabilityBubble';

export const TraceabilitySection = ({
  setHotspotFromSvg,
  activeHotspot,
  hotspots,
  productName,
  image,
  hotSpotStyles,
}) => {
  return (
    <Flex
      sx={{
        width: '100%',
        position: 'relative',
        flexDirection: 'column',
        justifyContent: 'center',
        pt: ['3rem', '5rem', '8rem'],
        pb: '5rem',
      }}
    >
      {/* Content Sub-Container no button */}
      <Flex
        sx={{
          width: '100%',
          height: '100%',
          flexDirection: ['column', 'row', 'row'],
          alignItems: ['center', null, null],
          position: 'relative',
        }}
      >
        {/* Middle img and hotspots container */}
        <Flex
          sx={{
            width: ['100%', '100%', '100%'],
            height: ['100%', '100%', '100%'],
            maxHeight: ['30rem', '30rem', '35rem'],
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            overflow: 'show',
            mb: '3rem',
            position: 'relative',
          }}
        >
          <GatsbyImage
            image={image.gatsbyImageData}
            alt="pad graphic"
            style={{
              height: '100%',
              maxHeight: ['30rem', '30rem', '35rem'],
              zIndex: 3,
              display: 'block',
            }}
            imgStyle={{
              maxHeight: ['30rem', '30rem', '35rem'],
              objectFit: 'contain',
              transform: productName === 'PADS' ? 'rotate(15deg)' : 'none',
            }}
          />
          <TraceabilityHotspots
            forwardSX={hotSpotStyles}
            setHotspotFromSvg={setHotspotFromSvg}
            activeHotspot={activeHotspot}
            hotspots={hotspots}
            productName={productName}
          />
        </Flex>
      </Flex>
      {/* DT Right // Mobile Bottom Text Container */}
      {activeHotspot && (
        <Box sx={{ display: ['block', 'block', 'none'], width: '100%' }}>
          <TreceabilityBubble
            setHotspotFromSvg={setHotspotFromSvg}
            activeHotspot={activeHotspot}
            hotspots={hotspots}
            productName={productName}
          />
        </Box>
      )}
    </Flex>
  );
};
