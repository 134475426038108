/** @jsx jsx */

import React, { useState } from 'react';
import { Box, Button, Flex, Text, jsx } from 'theme-ui';
import { useLocation } from '@reach/router';
import { Link } from 'gatsby';
import { useCart } from '@chordcommerce/gatsby-theme-autonomy';
import StructuredData from '~/components/Generic/StructuredData';
import ArrowRight from '~/assets/images/icons/arrow-right.svg';
import { useBoxBuilder } from '~/contexts/BoxBuilderPopUpContext';
import TextMarquee from '~/components/Generic/Marquee/Text';
import ArrowNE from '~/assets/images/icons/Arrow-NE.svg';
import { NonBoxSubSelectors } from './NonBoxSubSelectors';
import ProductQuantitySelector from '../QuantitySelector';
import useVariantAvailability from '~/hooks/components/useVariantAvailability';

export default function SubProdSelector({
  section,
  setSection,
  product,
  selectVariant,
  currentVariant,
  sizes,
  colors,
  hideOptions = false,
  ...props
}) {
  const {
    longDescription,
    shortDescription,
    slug,
    subscription: { intervals },
    defaultSubscriptionInterval,
  } = product;

  const { cart } = useCart();
  const { openModal } = useBoxBuilder();
  const { href } = useLocation();
  const [localSelectedInterval, setLocalSelectedInterval] = useState(defaultSubscriptionInterval);
  const { totalOnHand: currentVariantInStock } = useVariantAvailability({
    sku: currentVariant.sku,
  });
  const [selectedQuantity, setSelectedQuantity] = useState(1);

  const buildABoxCollection = product.collection.find((c) => c.slug === 'custom');
  const prodBox =
    product.sku === 'box-builder'
      ? buildABoxCollection.products.filter((prod) => prod.displayName === 'box')[0].variants[0]
      : null;

  const onIntervalChange = (e) => {
    const newInterval = intervals.find((s) => s.name === e.target.value) ?? null;
    setLocalSelectedInterval(newInterval);
  };

  const formatPrice = (number) => Number(Math.round(number * 100) / 100).toFixed(2);
  const discountPrice = product.price - product.price * 0.1;

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        pl: ['0', '5.5rem', '0'],
        justifyContent: 'flex-start',
        width: '100%',
      }}
      {...props}
    >
      {prodBox ? (
        <StructuredData>
          {{
            '@context': 'https://schema.org/',
            '@type': 'Product',
            name: product.name,
            image: product.mainImage.url,
            description: product.metaDescription,
            brand: {
              '@type': 'Brand',
              name: 'August',
            },
            offers: {
              '@type': 'Offer',
              priceCurrency: 'USD',
              price: Number(prodBox.price),
              availability: 'http://schema.org/InStock',
              url: href,
              seller: {
                '@type': 'Organization',
                name: 'August',
              },
            },
          }}
        </StructuredData>
      ) : null}
      {shortDescription && (
        <Text
          variant
          sx={{
            color: 'alizarinCrimson',
            fontSize: '1.5rem',
            fontStyle: 'italic',
            fontWeight: 800,
            mx: ['auto', 0, 0],
            textTransform: 'uppercase',
            textAlign: ['center', 'left', 'left'],
            mt: ['0.5rem'],
            width: ['28ch', '28ch', '38ch'],
          }}
        >
          {shortDescription}
        </Text>
      )}
      {/* Long Description */}
      <Box
        dangerouslySetInnerHTML={{
          __html: longDescription.childMarkdownRemark.html,
        }}
        sx={{
          color: 'alizarinCrimson',
          fontSize: ['1.3rem', '1.3rem', '1.4rem'],
          textAlign: ['center', 'left', 'left'],
          px: ['3.75rem', 0, 0],
          fontWeight: 400,
          lineHeight: '150%',
        }}
      />
      <Flex
        sx={{
          width: '100%',
          justifyContent: ['center', 'center', 'flex-start'],
          my: '2rem',
        }}
      >
        {prodBox ? (
          <Button
            onClick={openModal}
            sx={{
              variant: 'buttons.ctaHighlight',
            }}
          >
            Build A Box
            <ArrowRight />
          </Button>
        ) : (
          <Flex
            sx={{
              flexDirection: 'column',
              width: '100%',
              opacity: hideOptions ? 0.65 : 1,
              pointerEvents: hideOptions ? 'none' : 'all',
            }}
          >
            {/* Only show add to cart on bundles when cart his loaded (cart.id) to avoid race condition for 
            "InstantAddTocart" Param and avoid adding multiple same bundles */}
            {cart.id ? (
              <>
                <Box
                  sx={{
                    mb: '2rem',
                  }}
                >
                  <ProductQuantitySelector
                    handleChange={(value) => setSelectedQuantity(parseInt(value))}
                    currentVariantInStock={currentVariantInStock}
                    isHoodie={false}
                  />
                </Box>
                <NonBoxSubSelectors
                  setLocalSelectedInterval={setLocalSelectedInterval}
                  intervals={intervals}
                  localSelectedInterval={localSelectedInterval}
                  product={product}
                  formatPrice={formatPrice}
                  discountPrice={discountPrice}
                  onIntervalChange={onIntervalChange}
                  slug={slug}
                  currentVariant={currentVariant}
                  quantity={selectedQuantity}
                  hideOptions={hideOptions}
                />
              </>
            ) : null}
          </Flex>
        )}
      </Flex>
      <div data-publish-id="tww8optyljbiq" className="tolstoy-stories" />
      <Box as={Link} to="/impact-sustainability" sx={{ mb: '2.5rem', mt: '3rem' }}>
        <TextMarquee
          text="SEE YOUR IMPACT"
          symbol={<ArrowNE />}
          textColor="canary"
          backgroundColor="chatelle"
          fontWeight="bold"
          longText
          symbolFontSize
        />
      </Box>
    </Flex>
  );
}
