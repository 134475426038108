/** @jsx jsx */

import React from 'react';
import { Box, jsx } from 'theme-ui';
import { TreceabilityBubble } from '../TreceabilityBubble';
import { PulsatingDot } from '~/components/Generic/PulsatingDot/PulsatingDot';

export default function TraceabilityHotspots({
  forwardSX,
  activeHotspot,
  setHotspotFromSvg,
  hotspots,
  productName,
  size = 2,
  animationDuration = 1,
}) {
  return (
    <Box
      sx={{
        ...forwardSX,
        width: '100%',
        height: '100%',
        position: 'absolute',
        zIndex: 5,
        maxHeight: '35rem',
        maxWidth: '35rem',
      }}
    >
      {hotspots.map((spot, index) => {
        const isThisActive = activeHotspot?.positionId === spot.positionId;
        return (
          <PulsatingDot
            isThisActive={isThisActive}
            size={size}
            animationDuration={animationDuration}
            id={spot.positionId}
            setActive={setHotspotFromSvg}
          >
            <Box sx={{ display: ['none', 'none', 'block'] }}>
              {activeHotspot?.name === spot.name && (
                <TreceabilityBubble
                  setHotspotFromSvg={setHotspotFromSvg}
                  activeHotspot={activeHotspot}
                  hotspots={hotspots}
                  productName={productName}
                  spot={spot}
                />
              )}
            </Box>
          </PulsatingDot>
        );
      })}
    </Box>
  );
}
