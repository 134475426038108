/** @jsx jsx */

import React, { useState } from 'react';
import { Box, Flex, Text, jsx, useThemeUI } from 'theme-ui';
import { utils } from '@chordcommerce/gatsby-theme-autonomy';
import { useLocation } from '@reach/router';
import ProductSizeSelector from '~/components/Product/SizeSelector';
import ProductColorSelector from '~/components/Product/ColorSelector';
import ProductAddToCartButton from '~/components/Product/AddToCartButton';
import ProductQuantitySelector from '~/components/Product/QuantitySelector';
import StructuredData from '~/components/Generic/StructuredData';
import useVariantAvailability from '~/hooks/components/useVariantAvailability';
import GiftCardSelector from '../GiftCardSelector';
import GiftNoteForm from '../GiftNoteForm';

export default function NonSubProdSelector({
  section,
  setSection,
  product,
  selectVariant,
  currentVariant,
  sizes,
  colors,
  giftCardOptions,
  variants,
  ...props
}) {
  const { toUsdCurrency } = utils;

  const { href } = useLocation();

  const { longDescription, name, shippingAndReturns, slug } = product;

  const { comingSoon, soldOut, price, regularPrice } = currentVariant;

  const [selectedColor, setSelectedColor] = useState(colors ? colors[0] : null);

  const [selectedQuantity, setSelectedQuantity] = useState(1);

  const [selectedSize, setSelectedSize] = useState(sizes ? sizes[0] : null);

  const [selectedGiftCardOption, setSelectedGiftCardOption] = useState(
    giftCardOptions ? giftCardOptions[0] : null
  );

  const [giftNoteAttributes, setGiftNoteAttributes] = useState({
    recipientEmail: '',
    recipientName: '',
    message: '',
    sendOn: '',
  });

  const { totalOnHand: currentVariantInStock, isLoading: isFetchingAvailability } =
    useVariantAvailability({
      sku: currentVariant.sku,
    });

  const { theme } = useThemeUI();

  const nonDetailPanelSlugs = ['zipper-pouch', 'caps'];

  const shortDescSlugs = ['caps'];

  return (
    <Flex
      sx={{
        pl: ['1.1rem', '5.5rem', '0'],
        flexDirection: 'column',
      }}
      {...props}
    >
      <StructuredData>
        {{
          '@context': 'https://schema.org/',
          '@type': 'Product',
          name,
          image: product.mainImage.url,
          description: product.metaDescription,
          brand: {
            '@type': 'Brand',
            name: 'August',
          },
          offers: {
            '@type': 'Offer',
            priceCurrency: 'USD',
            price: regularPrice,
            availability: 'http://schema.org/InStock',
            url: href,
            seller: {
              '@type': 'Organization',
              name: 'August',
            },
          },
        }}
      </StructuredData>
      {shortDescSlugs.includes(slug) && (
        <Text
          variant
          sx={{
            color: 'alizarinCrimson',
            position: 'relative',
            fontSize: [3, 10, 12],
            fontStyle: 'italic',
            fontWeight: 500,
            textTransform: 'uppercase',
            mb: ['0rem', '1.5rem', '0.7rem'],
            mt: ['0.8rem'],
            width: ['100%', '70%', '90%'],
            lineHeight: '173%',
          }}
        >
          {product.shortDescription}
        </Text>
      )}
      {/* Description & Shipping */}
      <Flex
        sx={{
          mt: [!giftCardOptions.length && '1.3rem'],
          '& > div': {
            fontStyle: 'italic',
            cursor: "url('/images/blood.svg'), pointer",
            textTransform: 'uppercase',
            fontSize: [4, 5, 6],
            '&:first-of-type': {
              marginRight: '2.5rem',
              fontWeight: section === 'longDescription' ? 'bold' : 'regular',
              borderBottom: section === 'longDescription' ? '1px solid black' : 'none',
            },
            '&:last-of-type': {
              fontWeight: section === 'shippingAndReturns' ? 'bold' : 'regular',
              borderBottom: section === 'shippingAndReturns' ? '1px solid black' : 'none',
            },
          },
        }}
      >
        {!giftCardOptions.length && (
          <Text
            sx={{
              color: 'black',
              mr: '8px',
              fontStyle: 'italic',
              textDecoration: section === 'longDescription' ? 'underline' : 'none',
              cursor: "url('/images/blood.svg'), pointer",
            }}
            onClick={() => setSection('longDescription')}
          >
            Description
          </Text>
        )}
        {!nonDetailPanelSlugs && (
          <Text
            sx={{
              color: 'black',
              fontStyle: 'italic',
              textDecoration: section === 'shippingAndReturns' ? 'underline' : 'none',
              cursor: "url('/images/blood.svg'), pointer",
            }}
            onClick={() => setSection('shippingAndReturns')}
          >
            Shipping & Returns
          </Text>
        )}
      </Flex>
      <Box
        dangerouslySetInnerHTML={{
          __html:
            section === 'longDescription'
              ? longDescription.childMarkdownRemark.html
              : shippingAndReturns.childMarkdownRemark.html,
        }}
        sx={{
          color: 'alizarinCrimson',
          fontSize: [5, 4, 5],
          mt: ['1rem', '1.5rem', '1.2rem'],
          mb: ['1rem', '1.5rem', '1.9rem'],
          width: ['100%', '85%', '85%'],
          pr: ['1.1rem', '1rem', '1rem'],
        }}
      />
      <Flex
        sx={{
          flexDirection: 'column',
          mb: 0,
        }}
      >
        {!!sizes.length && (
          <ProductSizeSelector
            options={sizes}
            selectedSize={selectedSize}
            setSelectedSize={setSelectedSize}
            selectVariant={selectVariant}
          />
        )}

        {!!giftCardOptions.length && (
          <GiftCardSelector
            options={giftCardOptions}
            selectedOption={selectedGiftCardOption}
            setSelectedOption={setSelectedGiftCardOption}
            selectVariant={selectVariant}
          />
        )}

        {/* Chooses whether to display a sale price */}
        {!product.giftCard && (
          <>
            {price === regularPrice ? (
              <Text
                sx={{
                  color: 'alizarinCrimson',
                  fontSize: [8, 10, 12],
                  fontStyle: 'italic',
                  mt: ['1rem'],
                  fontWeight: 'bold',
                }}
              >
                {toUsdCurrency(price)}
              </Text>
            ) : (
              <Flex
                sx={{
                  color: 'alizarinCrimson',
                  fontSize: [5, 10, 10],
                  fontStyle: 'italic',
                  fontWeight: 'bold',
                  alignItems: 'flex-end',
                }}
              >
                <Text
                  sx={{
                    color: 'discountGreen',
                    mr: ['0.6rem', '0.8rem', '1rem'],
                  }}
                >
                  {toUsdCurrency(price)}
                </Text>
                <Text
                  sx={{
                    textDecoration: 'line-through',
                    mr: '0.3rem',
                  }}
                >
                  {toUsdCurrency(regularPrice)}
                </Text>
              </Flex>
            )}
          </>
        )}

        {product.giftCard && (
          <GiftNoteForm values={giftNoteAttributes} setValues={setGiftNoteAttributes} />
        )}

        {!product.giftCard && (
          <Flex
            sx={{
              flexDirection: 'column',
              alignItems: 'flex-start',
              mt: ['1.9rem'],
              mb: ['1.9rem'],
            }}
          >
            <>
              <Text
                sx={{
                  fontWeight: 700,
                  mr: '1rem',
                  fontStyle: 'italic',
                  color: 'black',
                  fontSize: ['15px'],
                  mb: ['0.5rem'],
                }}
              >
                QTY
              </Text>
              <ProductQuantitySelector
                handleChange={(value) => setSelectedQuantity(parseInt(value))}
                currentVariantInStock={currentVariantInStock}
                isHoodie={product.sku.includes('hoodie')}
              />
            </>
          </Flex>
        )}

        {!!colors.length && (
          <>
            <Flex
              sx={{
                textTransform: 'uppercase',
                fontStyle: 'italic',
                color: 'black',
                fontSize: [4, 5, 6],
              }}
            >
              <Text sx={{ fontWeight: 700, mr: '0.5rem' }}>color:</Text>
              <Text>{selectedColor.presentation}</Text>
            </Flex>

            <ProductColorSelector
              sx={{ maxWidth: '8.2rem' }}
              options={colors}
              selectedColor={selectedColor}
              setSelectedColor={setSelectedColor}
              selectVariant={selectVariant}
            />
          </>
        )}
      </Flex>
      <ProductAddToCartButton
        soldOut={nonDetailPanelSlugs.includes(slug) ? product.soldOut : soldOut}
        currentVariantInStock={currentVariantInStock}
        isFetchingAvailability={isFetchingAvailability}
        comingSoon={comingSoon}
        price={price}
        quantity={selectedQuantity}
        regularPrice={regularPrice}
        sku={currentVariant.sku}
        currentVariant={currentVariant}
        slug={slug}
        name={name}
        isGiftCard={product.giftCard}
        giftNoteAttributes={giftNoteAttributes}
        resetGiftNoteAttributes={() => setGiftNoteAttributes({})}
        sx={{
          display: 'flex',
          height: '30px',
          border: `1px solid ${theme.colors.alizarinCrimson}`,
        }}
      />
    </Flex>
  );
}
