/** @jsx jsx */
import { useEffect, useRef } from 'react';
import { Flex, jsx, Heading, useThemeUI } from 'theme-ui';
import useWindowSize from '~/hooks/components/use-window-size';
import useOnScreen from '~/hooks/components/use-on-screen';

export default function YotpoReviewWidget({ slug }) {
  const { width: windowWidth } = useWindowSize();
  const { theme } = useThemeUI();
  const ref = useRef();
  const onScreen = useOnScreen(ref, '0px', true);

  // This changes all "Name" default user names to "Anonymous"
  useEffect(() => {
    if (typeof window !== 'undefined' && onScreen) {
      const changeNameToAnon = () => {
        const reviewerNames = document.querySelectorAll('.yotpo-user-name');
        reviewerNames.forEach((x) => {
          if (x.innerText === 'Name') x.innerText = 'Anonymous';
        });
      };
      changeNameToAnon();
      // Select the node that will be observed for mutations
      const reviewsList = document.getElementsByClassName('yotpo-reviews')[0];

      // Options for the observer (which mutations to observe)
      const config = { attributes: true, childList: true, subtree: true };

      // Create an observer instance linked to the callback function
      const observer = new MutationObserver(changeNameToAnon);

      // Start observing the target node for configured mutations

      reviewsList && observer.observe(reviewsList, config);
    }
  }, [onScreen]);

  return (
    <Flex
      ref={ref}
      sx={{
        px: [0],
        mb: '44px',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
        '#yotpoReviewsWidget .main-widget .bottom-line-items': {
          marginBottom: [0],
        },
        '#yotpoReviewsWidget .yotpo-reviews': {
          display: [null, 'flex !important'],
          flexFlow: 'row wrap',
          justifyContent: 'center',
          alignItems: 'stretch',
        },
        '#yotpoReviewsWidget .yotpo-review': {
          width: [null, 'calc(50% - 20px)', null, 'calc(33% - 30px)'],
          backgroundColor: '#E2DCEF',
          borderRadius: '10px',
          padding: '4rem 2rem !important',
          border: 0,
          ':not(:last-of-type)': {
            mb: '2rem',
          },
        },
      }}
    >
      <Heading
        as="h3"
        sx={{
          textTransform: 'uppercase',
          fontSize: ['2.4rem', null, '40px'],
          mt: ['30px', '16px', 0],
          mb: '16px',
        }}
      >
        Reviews
      </Heading>

      <div
        className="yotpo yotpo-main-widget"
        id="yotpoReviewsWidget"
        data-product-id="box-builder"
        data-name="Box Builder"
      />
    </Flex>
  );
}
