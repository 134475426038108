import React, { useState } from 'react';
import { Box, Button, Flex, Text } from 'theme-ui';
import { graphql, useStaticQuery } from 'gatsby';
import TextMarquee from '~/components/Generic/Marquee/Text';
import useWindowSize from '~/hooks/components/use-window-size';
import { TraceabilitySection } from '../TraceabilitySection';
import { PdpButton } from '../PdpButton';

export const WhatsInYourPeriodCare = ({ slug, setToolTipShown }) => {
  const data = useStaticQuery(graphql`
    query {
      pads: contentfulHotspotsImage(name: { eq: "pads traceability" }) {
        name
        header
        hotspots {
          name
          area
          text
          displayName
          positionId
        }
        image {
          gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 500)
        }
      }
      tampons: contentfulHotspotsImage(name: { eq: "tampons traceability" }) {
        name
        header
        hotspots {
          name
          area
          text
          displayName
          positionId
        }
        image {
          gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 500)
        }
      }
      liners: contentfulHotspotsImage(name: { eq: "liners traceability" }) {
        name
        header
        hotspots {
          name
          area
          text
          displayName
          positionId
        }
        image {
          gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 500)
        }
      }
    }
  `);

  const { pads, tampons, liners } = data;
  const allHotSpots = [...pads.hotspots, ...tampons.hotspots, ...liners.hotspots];
  const [activeHotspot, setActiveHotspot] = useState(null);
  const [selectedTab, setSelectedTab] = useState(1);
  const { width } = useWindowSize();

  const setHotspotFromBubble = (positionId) => {
    console.log(positionId);
    setActiveHotspot(allHotSpots.filter((item) => item.positionId === positionId)[0]);
  };

  const tabArray = [
    {
      title: 'PADS',
      data: pads,
      hotSpotStyles: {
        '#pads-ne': {
          top: '25%',
          left: '78%',
        },
        '#pads-sw': {
          top: '70%',
          left: '38%',
        },
        '#pads-nw': {
          top: '25%',
          left: '25%',
        },
        '#pads-ne-bubble': {
          top: '200%',
          left: '-250%',
          width: '20ch',
        },
        '#pads-sw-bubble': {
          top: '250%',
          left: '-500%',
          width: '35ch',
        },
        '#pads-nw-bubble': {
          top: '-950%',
          left: '-300%',
          width: '24ch',
        },
      },
    },
    {
      title: 'TAMPONS',
      data: tampons,
      hotSpotStyles: {
        '#tampons-se': {
          top: '65%',
          left: '68%',
        },
        '#tampons-sw': {
          top: '58%',
          left: '40%',
        },
        '#tampons-nw': {
          top: '0%',
          left: '37%',
        },
        '#tampons-se-bubble': {
          top: '-100%',
          left: '200%',
          width: '18ch',
        },
        '#tampons-sw-bubble': {
          top: '225%',
          left: '-750%',
          width: '18ch',
        },
        '#tampons-nw-bubble': {
          top: '-625%',
          left: '-150%',
          width: '30ch',
        },
      },
    },
    {
      title: 'LINERS',
      data: liners,
      hotSpotStyles: {
        '#liners-se': {
          top: '65%',
          left: '68%',
        },
        '#liners-nw': {
          top: '5%',
          left: '30%',
        },
        '#liners-se-bubble': {
          top: '200%',
          left: '-450%',
          width: '20ch',
        },
        '#liners-nw-bubble': {
          top: '-550%',
          left: '-500%',
          width: '30ch',
        },
      },
    },
  ];

  return (
    <Flex
      sx={{
        width: '100%',
        height: 'fit-content',
        mt: 0,
        mb: ['2rem', '1rem', '1rem'],
        flexDirection: 'column',
        backgroundColor: '#71c3ef80',
        pl: ['2.2rem', '2.2rem', '5rem'],
        pr: ['2.2rem', '2.2rem', '5rem'],
        pt: '5rem',
        pb: 0,
        borderRadius: '1rem',
        overflow: 'hidden',
        justifyContent: 'space-between',
      }}
    >
      {/* Top Header Container */}
      <Flex
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Text
          sx={{
            fontSize: '2.4rem',
            fontWeight: '800',
            textTransform: 'uppercase',
            textAlign: 'center',
            mb: '3rem',
            width: ['15ch', '15ch', '100%'],
          }}
        >
          WHAT’S IN YOUR PERIOD CARE?
        </Text>
      </Flex>
      <Flex sx={{ flexDirection: 'column' }}>
        <Flex
          sx={{
            width: '100%',
            height: '3rem',
            mb: ['3rem', '3rem', '7rem'],
            borderRadius: '0.5rem',
            display: ['flex', 'flex', 'none'],
          }}
        >
          {tabArray?.map((tab, index) => {
            const isThisSelected = index + 1 === selectedTab;
            return (
              <Flex
                as={Button}
                sx={{
                  width: '33.33%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: isThisSelected ? 'alizarinCrimson' : 'none',
                  borderColor: 'alizarinCrimson',
                  borderStyle: 'solid',
                  borderRadius:
                    index === 0 ? '0.5rem 0 0 0.5rem' : index === 1 ? 0 : '0 0.5rem 0.5rem 0',
                }}
                onClick={() => {
                  setSelectedTab(index + 1);
                  setActiveHotspot(null);
                }}
              >
                <Text
                  sx={{
                    color: isThisSelected ? '#fff' : 'alizarinCrimson',
                    textAlign: 'center',
                    fontSize: '1.3rem',
                  }}
                >
                  {tab.title}
                </Text>
              </Flex>
            );
          })}
        </Flex>
        <Flex sx={{ width: '100%' }}>
          {tabArray?.map(
            (tab, index) =>
              (selectedTab === index + 1 || width > 830) && (
                <TraceabilitySection
                  setHotspotFromSvg={setHotspotFromBubble}
                  activeHotspot={activeHotspot}
                  hotspots={tab.data.hotspots}
                  productName={tab.title}
                  image={tab.data.image}
                  // position of the hotpspots
                  hotSpotStyles={tab.hotSpotStyles}
                />
              )
          )}
        </Flex>
      </Flex>
      {/* Bottom Button Container */}
      <PdpButton url="/traceability" linkText="See Traceability" />
      <Box sx={{ mx: '-5rem' }}>
        <TextMarquee
          text="•&nbsp;&nbsp;&nbsp;Hypoallergenic&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;Non-Toxic&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;FDA-Approved&nbsp;&nbsp;&nbsp;"
          symbol=""
          textColor="#fff"
          backgroundColor="alizarinCrimson"
          fontWeight="bold"
          longText
          height="5rem"
          symbolFontSize={0}
          pauseOnHover
          setToolTipShown={setToolTipShown}
        />
      </Box>
    </Flex>
  );
};
