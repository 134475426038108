/** @jsx jsx */
import React, { useState, useRef, useContext } from 'react';
import { Box, Flex, Heading, Text, jsx } from 'theme-ui';
import _ from 'lodash';
import NonSubProdSelector from '~/components/Product/NonSubProdSelector';
import useProductVariants from '~/hooks/components/use-product-variants';
import YotpoStars from '~/components/Product/YotpoStars';
import useWindowSize from '~/hooks/components/use-window-size';
import VariantContext from '~/contexts/VariantContext';
import Accordion from '../Accordion';
import SubProdSelector from '../SubProdSelector';

const ProductDetails = ({ periodProducts, product, alreadyInCart = false, ...props }) => {
  const scrollTarget = useRef();
  const { name, subscription, variants, optionTypes, slug, sku } = product;
  const [section, setSection] = useState('longDescription');
  const [activeAccordionIndex, setActiveAccordionIndex] = useState(-1);

  const { width: windowWidth } = useWindowSize();

  const colors = optionTypes?.find((o) => o.slug === 'color')?.optionValues ?? [];

  const sizes = optionTypes?.find((o) => o.slug === 'size')?.optionValues ?? [];

  const giftCardOptions = optionTypes?.find((o) => o.slug === 'value')?.optionValues ?? [];

  const { currentVariant, selectVariant } = useProductVariants(variants);
  const [variant] = useContext(VariantContext);

  const nonDetailPanelSlugs = ['zipper-pouch'];
  const hideOptons = ['travel-20ct', 'august-sample', 'august-sample-longnight'].includes(sku);
  // The below array will be used to check whether or not to display reviews for a product
  // Currently we are only displaying reviews for period products so this will be modified in the future when other products are reviewed

  return (
    <Box id="product-details" {...props} ref={scrollTarget}>
      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: ['center', 'flex-start', 'flex-start'],
          justifyContent: 'flex-start',
        }}
      >
        {slug !== 'sample-box' && (
          <Heading
            as="h2"
            variant="h2"
            sx={{
              color: 'alizarinCrimson',
              textTransform: 'uppercase',
              width: ['80%'],
              fontSize: [13, 18, windowWidth < 900 ? '4.1rem' : 20],
              textAlign: ['center', 'left', 'left'],
              lineHeight: ['29px', '29px', '46px'],
              pl: ['0', '5.5rem', '0'],
              mt: [0, 0, '10px'],
            }}
          >
            {name}
          </Heading>
        )}
        {periodProducts.includes(slug) && <YotpoStars slug={slug} />}
      </Flex>
      {/* Selector Sections */}
      {subscription ? (
        <SubProdSelector
          section={section}
          setSection={setSection}
          product={product}
          sizes={sizes}
          colors={colors}
          selectVariant={selectVariant}
          currentVariant={variant || currentVariant}
          hideOptions={alreadyInCart && hideOptons}
        />
      ) : (
        currentVariant && (
          <NonSubProdSelector
            section={section}
            setSection={setSection}
            product={product}
            sizes={sizes}
            colors={colors}
            giftCardOptions={giftCardOptions}
            variants={variants}
            selectVariant={selectVariant}
            currentVariant={variant || currentVariant}
          />
        )
      )}
      {/* Detail Sections */}
      <Flex
        sx={{
          my: [0, '1.5rem', '1.5rem'],
          flexDirection: 'column',
          pl: ['0', '5.5rem', '0'],
          pr: ['0', '4.2rem', '0'],
        }}
      >
        {!nonDetailPanelSlugs.includes(product.slug) &&
          _.map(product.detailSections, (detailSection, index) => (
            <Accordion
              header={detailSection.displayName}
              forwardSX={{
                height: '7rem',
                borderBottomWidth: '0.2rem',
                '.c-accordion__header': {
                  fontSize: 11,
                  paddingY: '0 !important',
                },
                '.c-accordion__header-icon': {
                  width: '4rem',
                  height: '4rem',
                },
              }}
              isExpanded={index === activeAccordionIndex}
              onToggle={() => {
                setActiveAccordionIndex(index === activeAccordionIndex ? -1 : index);
              }}
              render={() => (
                <Box
                  sx={{
                    fontSize: 5,
                    paddingY: '2rem',
                  }}
                  dangerouslySetInnerHTML={{ __html: detailSection.body.childMarkdownRemark.html }}
                />
              )}
              key={`${detailSection.displayName}+${product.slug}`}
            />
          ))}
      </Flex>
    </Box>
  );
};

export default ProductDetails;
