/* eslint-disable no-nested-ternary */
/** @jsx jsx */
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { Box, Container, Flex, Text, jsx } from 'theme-ui';
import { graphql, useStaticQuery } from 'gatsby';
import _ from 'lodash';
import { useCart } from '@chordcommerce/gatsby-theme-autonomy';
import ImageCarousel from '~/components/Generic/ImageCarousel';
import RelatedProducts from '~/components/Generic/RelatedProducts';
import ProductDetails from '~/components/Product/Details';
import ImageMarquee from '~/components/Generic/Marquee/Image';
import Spacer from '~/components/Generic/Layout/Spacer';
import TextMarquee from '~/components/Generic/Marquee/Text';
import useProductVariants from '~/hooks/components/use-product-variants';
import VariantContext from '~/contexts/VariantContext';
import useWindowSize from '~/hooks/components/use-window-size';
import YotpoReviewWidget from '~/components/Product/YotpoReviewWidget';
import { periodProducts } from '~/lib/products';
import { configureFatMedia } from '~/utils/fat-media';
import Shipping from '~/assets/images/icons/shipping.svg';
import { WhatsInYourPeriodCare } from '../WhatsInYourPeriodCare';
import useEventListener from '~/hooks/components/use-event-listener';

const ProductPage = ({ product }) => {
  const [spacerIsVisible, setSpacerIsVisible] = useState(false);

  const { width: windowWidth } = useWindowSize();

  configureFatMedia({ pageType: 'PRODUCT', productId: product.slug });

  const data = useStaticQuery(graphql`
    query {
      action: contentfulPageSection(slug: { eq: "product-hoodies-in-action" }) {
        marqueeImages {
          image {
            gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 500)
            file {
              details {
                image {
                  height
                  width
                }
              }
            }
          }
          altText
          link
          label {
            raw
          }
        }
        heading {
          raw
        }
      }
    }
  `);

  const { action } = data;

  const { relatedProducts, variants, slug, sku } = product;

  const { cart } = useCart();
  const collection = product.collection?.find((c) => c.slug !== 'shop-all-collection');

  const { currentVariant } = useProductVariants(variants);

  const [variant, setVariant] = useContext(VariantContext);
  const [images, setImages] = useState(variant?.images ? variant.images : currentVariant.images);
  const [toolTipShown, setToolTipShown] = useState(false);
  const [toolTipPosition, setToolTipPosition] = useState({ x: 0, y: 0 });

  const subscriptionSKU = slug === 'box-builder' || !!product?.subscription?.slug;

  useEffect(() => {
    setVariant(variants[0]);
  }, []);

  useEffect(() => {
    setImages(variant?.images ? variant.images : currentVariant.images);
  }, [variant]);

  useLayoutEffect(() => {
    if (slug === 'box-builder') {
      const timeout = setTimeout(() => {
        const hash = typeof window !== 'undefined' ? window.location.hash : null;
        if (hash) {
          const scrollSection = document.getElementById(hash.substring(1));
          scrollSection.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }, 1000);
      return () => clearTimeout(timeout);
    }
    return () => {};
  }, [slug]);

  const { marqueeImages, heading } = action;
  const imageNodes = _.map(marqueeImages, (marqueeImage) => ({
    description: marqueeImage.altText,
    file: marqueeImage.image?.file,
    link: marqueeImage.link,
    gatsbyImageData: marqueeImage.image?.gatsbyImageData,
  }));

  // This calculates a number inversely related to the window's width
  // to accomodate the expanded detail sections

  const onMouseMove = (e) => {
    if (window.innerWidth > 830) {
      const tooltip = document.getElementById('whatsOnYourPeriodCareTooltip');
      const rect = e.currentTarget.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      const positionLeft =
        window.innerWidth < tooltip.offsetWidth + x ? x - tooltip.offsetWidth - 50 : x;
      const positionTop =
        window.innerHeight < tooltip.offsetWidth + y ? y - tooltip.offsetHeight - 50 : y;
      tooltip.style.left = `${positionLeft + 20}px`;
      tooltip.style.top = `${positionTop + 20}px`;
    }
  };

  const alreadyInCart = cart?.lineItems?.find((item) => item.variant.sku === sku);
  return (
    <>
      <img
        height="1"
        width="1"
        style={{ borderStyle: 'none' }}
        alt=""
        src="https://insight.adsrvr.org/track/pxl/?adv=7eghy5v&ct=0:wl0eebr&fmt=3"
      />

      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          maxWidth: '100vw',
        }}
      >
        <TextMarquee
          text="Free shipping on all orders over $50!"
          symbol={<Shipping />}
          textColor="canary"
          backgroundColor="alizarinCrimson"
          fontWeight="bold"
          longText
          symbolFontSize
        />
        {/* Images and product details container */}
        <Flex
          sx={{
            mt: ['3.8rem', 0, 0],
            mb: ['3.5rem'],
            flexDirection: ['column', 'column', 'row'],
          }}
        >
          {/* Image Carousel */}
          <Box
            sx={{
              display: ['block', 'block', 'block'],
              width: [
                windowWidth < 832 ? '103vw' : 'calc(140% + 1rem + 1rem)',
                null,
                'min(50vw, 110rem)',
              ],
              position: 'relative',
              left: [-3, 0, 0],
              right: [windowWidth < 832 ? '1rem' : '4rem', 0, 0],
              m: ['auto', 'auto', '2rem 2rem 0 0'],
              aspectRatio: '1',
              '& .carousel-slider': {
                overflow: 'hidden',
              },
              '& .slider-wrapper': {
                overflow: 'hidden',
              },
            }}
          >
            <ImageCarousel images={images} contain={!!product.giftCard} />
          </Box>
          <ProductDetails
            product={product}
            sx={{
              width: ['100%', '100%', '50%'],
              minWidth: ['100%', '100%', '40vw'],
              maxWidth: ['100%', '100%', '60vw'],
              marginTop: '3rem',
              alignSelf: ['center', null, 'unset'],
              display: 'flex',
              flexDirection: 'column',
            }}
            periodProducts={periodProducts}
            spacerIsVisible={spacerIsVisible}
            setSpacerIsVisible={setSpacerIsVisible}
            alreadyInCart={alreadyInCart}
          />
        </Flex>
        <Box id="upperSticky" /> {/* show the build-a-box sticky after this */}
        <Box sx={{ position: 'relative' }} onMouseMove={onMouseMove}>
          {subscriptionSKU ? <WhatsInYourPeriodCare setToolTipShown={setToolTipShown} /> : null}
          <Box
            sx={{
              position: 'absolute',
              height: 'fit-content',
              width: '24ch',
              bottom: ['-8rem', '8rem', toolTipPosition.x],
              left: ['50%', '50%', toolTipPosition.y],
              transform: ['translate(-50%,0)', 'translate(-50%,0)', 'unset'],
              backgroundColor: 'canary',
              p: '1rem',
              borderRadius: '1rem',
              textAlign: 'center',
              border: '1px solid #CF2029',
              display: toolTipShown ? 'block' : 'none',
            }}
            id="whatsOnYourPeriodCareTooltip"
          >
            <Text sx={{ fontSize: '1.2rem', fontWeight: 400, lineHeight: '150%' }}>
              Hypoallergenic and free of pesticides, herbicides, fragrances, rayon, deodorants,
              dioxins, dyes, and harmful chemicals.
            </Text>
          </Box>
        </Box>
        {slug !== 'sample-box' && <Spacer height={[null, null, '10rem']} />}
        {periodProducts.includes(slug) && (
          <YotpoReviewWidget windowWidth={windowWidth} slug={slug} />
        )}
        {relatedProducts && (
          <RelatedProducts products={relatedProducts} collectionName={collection.slug} />
        )}
        <Box
          sx={{
            width: ['calc(100% + 8rem)'],
            position: 'relative',
            right: '4rem',
            h2: {
              ml: '8.2rem',
            },
          }}
        >
          <ImageMarquee images={imageNodes} heading={heading} spinner offsetImages />
        </Box>
        <Box id="lowerSticky" /> {/* hide the build-a-box sticky after this */}
      </Container>
    </>
  );
};

export default ProductPage;
