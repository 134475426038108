import React, { useState } from 'react';
import { Box, Flex, Label, Radio, Text } from 'theme-ui';
import ProductAddToCartButton from '../AddToCartButton';
import { DeliverySelectionDropdown } from '~/components/Generic/DeliverySelectionDropdown';

export const NonBoxSubSelectors = ({
  setLocalSelectedInterval,
  intervals,
  localSelectedInterval,
  product,
  formatPrice,
  discountPrice,
  onIntervalChange,
  slug,
  currentVariant,
  hideOptions,
  quantity = 1,
}) => {
  const subOptions = [
    { name: 'SUBSCRIBE AND SAVE 10%', isSubscription: true },
    { name: 'BUY ONE TIME', isSubscription: false },
  ];
  const [selectedOption, setSelectedOption] = useState('SUBSCRIBE AND SAVE 10%');

  const optionSelectionhandler = (option) => {
    option === 'SUBSCRIBE AND SAVE 10%'
      ? !localSelectedInterval
        ? setLocalSelectedInterval(intervals[0])
        : null
      : setLocalSelectedInterval(null);
    setSelectedOption(option);
  };

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        width: '100%',
        maxWidth: '60rem',

        'input:focus': {
          outline: 'none',
        },
      }}
    >
      {subOptions.map((option, index) => {
        const { name, isSubscription } = option;
        const isActive = name === selectedOption;
        return (
          <Flex
            key={name + index}
            sx={{
              borderRadius: '5px',
              py: ['1rem', '1.5rem', '1.5rem'],
              px: ['0.8rem', '1rem', '1rem'],
              flexDirection: 'column',
              color: 'canary',
              backgroundColor: 'alizarinCrimson',
              fontStyle: 'italic',
              fontSize: ['12px', '12px', '13px'],
              width: '100%',
              mb: '2rem',
              justifyContent: 'center',
              alignItems: 'space-between',
              maxHeight: '10rem',
              minHeight: isActive ? '10rem' : 'auto',
            }}
            onClick={() => optionSelectionhandler(name)}
          >
            <Flex sx={{ width: '100%', height: '50%', justifyContent: 'space-between' }}>
              <Label
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mb: '1rem',
                }}
              >
                <Radio
                  name="dark-mode"
                  value={option}
                  checked={isActive}
                  sx={{
                    color: '#e6fc5c !important',
                    backgroundColor: 'transparent !important',
                  }}
                />
                {name}
              </Label>
              <Flex sx={{ mb: '1rem', width: '100%', justifyContent: 'flex-end' }}>
                <Text
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mr: '0.75rem',
                    textDecoration: isSubscription ? 'line-through' : 'none',
                    color: 'chatelle',
                    textAlign: 'right',
                  }}
                >
                  ${formatPrice(product.price)}
                </Text>
                {isSubscription ? (
                  <Text
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      textAlign: 'right',
                    }}
                  >
                    {`$${formatPrice(discountPrice)}`}
                  </Text>
                ) : null}
              </Flex>
            </Flex>
            {isActive ? (
              <Flex sx={{ width: '100%', height: '50%', justifyContent: 'space-between' }}>
                {isSubscription && (
                  <Box
                    sx={{
                      width: '50%',
                      svg: {
                        fill: 'darkTan',
                      },
                    }}
                  >
                    <DeliverySelectionDropdown
                      onChange={onIntervalChange}
                      selectedIntervalName={localSelectedInterval?.name}
                      options={intervals}
                      showPlaceholder={false}
                      showOneTime={false}
                      forwardSX={{
                        height: '4rem',
                        minWidth: 'min(22.5rem, 100%)',
                        maxWidth: 'min(22.5rem, 100%)',
                      }}
                    />
                  </Box>
                )}
                <ProductAddToCartButton
                  soldOut={false}
                  currentVariantInStock
                  comingSoon={false}
                  price={product.price}
                  quantity={quantity}
                  regularPrice={product.price}
                  sku={currentVariant.sku}
                  currentVariant={currentVariant}
                  slug={slug}
                  name={product.name}
                  subscription={product.subscription}
                  interval={localSelectedInterval}
                  forwardSX={{
                    display: 'flex',
                    height: '4rem',
                    width: isSubscription ? '40%' : '100%',
                    justifyContent: 'flex-end',
                    mt: 0,
                    mb: 0,
                    ml: '1rem',
                  }}
                  hideOptions={hideOptions}
                />
              </Flex>
            ) : null}
          </Flex>
        );
      })}
    </Flex>
  );
};
