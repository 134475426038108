/** @jsx jsx */
import { jsx, Heading } from 'theme-ui';

import React from 'react';
import { graphql } from 'gatsby';
import Layout from '~/components/Layout';
import Metadata from '~/components/Metadata';
import ProductPage from '~/components/Product/Page';

const ProductTemplate = ({ data }) => {
  const { product } = data;

  return (
    <Layout hideWidget={product.sku !== 'box-builder'}>
      <Metadata
        title={product.metaTitle ?? product.name}
        description={product.metaDescription ?? product.shortDescription}
      />
      <ProductPage product={product} />
    </Layout>
  );
};

export const query = graphql`
  query ProductQuery($slug: String, $locale: String) {
    product: contentfulProduct(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      ...ProductPageFragment
    }
  }
`;

export default ProductTemplate;
