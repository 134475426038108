/** @jsx jsx */
import { Box, Field, Grid, jsx } from 'theme-ui';

const GiftNoteForm = ({ values, setValues }) => {
  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);

  return (
    <Box sx={{ my: '2rem' }}>
      <Grid
        sx={{
          gridTemplateColumns: '1fr',
          gap: '1rem',
          fontSize: [2],
          input: {
            fontSize: '1.1rem !important',
            mt: '1rem',
            ':focus': {
              outline: 'none',
            },
          },
        }}
      >
        <Field
          value={values.recipientEmail}
          onChange={({ target: { value } }) => setValues({ ...values, recipientEmail: value })}
          autoFocus
          label="Recipient Email (Required)"
          type="email"
          required
        />
        <Field
          value={values.recipientName}
          onChange={({ target: { value } }) => setValues({ ...values, recipientName: value })}
          label="Recipient Name"
          type="text"
        />
        <Field
          value={values.message}
          onChange={({ target: { value } }) => setValues({ ...values, message: value })}
          label="Message"
          type="text"
        />
        <Field
          value={values.sendOn}
          onChange={({ target: { value } }) => setValues({ ...values, sendOn: value })}
          label="Send On (Leave blank to send same-day)"
          min={`${tomorrow.getFullYear()}-${tomorrow.getMonth() + 1}-${tomorrow.getDate()}`}
          type="date"
        />
      </Grid>
    </Box>
  );
};

export default GiftNoteForm;
