import React from 'react';
import Helmet from 'react-helmet';

export default function StructuredData({ children }) {
  return (
    <Helmet>
      <script id="structuredData" type="application/ld+json">
        {JSON.stringify(children)}
      </script>
    </Helmet>
  );
}
