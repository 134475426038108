import React from 'react';
import { Flex, Text } from 'theme-ui';

export const TreceabilityBubble = ({
  setHotspotFromSvg,
  activeHotspot,
  hotspots,
  productName,
  image,
  hotSpotStyles,
  forwardBubbleSX,
  spot,
}) => {
  return (
    <Flex
      id={`${spot?.positionId}-bubble`}
      sx={{
        width: ['100%', '100%', 'fit-content'],
        backgroundColor: 'lightPurple',
        boxShadow: '3px 3px 0 0 #CF2029',
        border: '1px solid #CF2029',
        borderRadius: '1rem',
        p: '1rem',
        textAlign: 'left',
        fontSize: '1.2rem',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: ['center', 'flex-start', 'flex-start'],
        height: 'fit-content',
        position: ['unset', 'unset', 'absolute'],
        // mb: '5rem',
      }}
    >
      <Text
        sx={{
          fontWeight: 700,
          width: '100%',
          pb: ['0.5rem'],
          textTransform: 'uppercase',
        }}
      >
        {activeHotspot && activeHotspot.displayName}
      </Text>
      <Text
        sx={{
          lineHeight: '150%',
          textTransform: 'none',
          width: '100%',
        }}
      >
        {activeHotspot && activeHotspot.text}
      </Text>
    </Flex>
  );
};
