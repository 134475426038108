/** @jsx jsx */
import { Flex, jsx, Box, Spinner, Text, useThemeUI } from 'theme-ui';

const GiftCardSelector = ({
  handleChange,
  options = [],
  setSelectedOption,
  selectedOption,
  selectVariant,
  ...props
}) => {
  const { theme } = useThemeUI();
  const { isLoading } = props;
  const sizeOrder = { $25: 0, $50: 1, $75: 2, $100: 3 };

  const sortedOptions = [...options].sort(
    (a, b) => sizeOrder[a.presentation] - sizeOrder[b.presentation]
  );

  return (
    <Flex
      sx={{
        minWidth: '80px',
      }}
    >
      {isLoading && <Spinner color="inherit" />}
      {!isLoading &&
        sortedOptions.map((option) => (
          <Box
            sx={{
              height: '38px',
              width: '56px',
              borderRadius: '5px',
              border: '1px solid black',
              backgroundColor: `${
                selectedOption === option ? theme.colors.alizarinCrimson : 'white'
              }`,
              color: `${selectedOption === option ? 'white' : 'black'}`,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '0 2px',
              cursor: "url('/images/blood.svg'), pointer",
            }}
            key={option.presentation}
            onClick={() => {
              setSelectedOption(option);
              selectVariant(option);
            }}
          >
            <Text
              sx={{
                fontWeight: 700,
                fontStyle: 'italic',
                fontSize: '15px',
              }}
            >
              {option.presentation}
            </Text>
          </Box>
        ))}
    </Flex>
  );
};

export default GiftCardSelector;
